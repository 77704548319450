module.exports = {
  siteTitle: 'EasyCoders - code made easy', // <title>
  shortSiteTitle: 'EasyCoders - code made easy', // <title> ending for posts and pages
  siteDescription: 'EasyCoders makes coding easy.',
  siteUrl: 'https://easycoders.io',
  pathPrefix: '',
  siteImage: 'preview.png',
  siteLanguage: 'en',

  /* author */
  authorName: 'matthew brimmer',
  authorTwitterAccount: '@easy_coders',

  /* info */
  headerTitle: 'EasyCoders',
  headerSubTitle: '',

  /* manifest.json */
  manifestName: 'EasyCoders - code made easy',
  manifestShortName: 'EasyCoders', // max 12 characters
  manifestStartUrl: '/index.html',
  manifestBackgroundColor: 'white',
  manifestThemeColor: '#666',
  manifestDisplay: 'standalone',

  // gravatar
  // Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
  // Replace your email adress with md5-code.
  // Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
  // gravatarImgMd5: "https://www.gravatar.com/avatar/1db853e4df386e8f699e4b35505dd8c6",
  gravatarImgMd5: '',

  // social
  authorSocialLinks: [
    { name: 'github', url: 'https://github.com/easycodersio' },
    { name: 'twitter', url: 'https://twitter.com/easy_coders' },
    { name: 'facebook', url: 'https://www.facebook.com/EasyCoders-2267425543515879/' }
  ]
}
