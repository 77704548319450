import React from 'react'
import PropTypes from 'prop-types'
import EmailForm from './Email'

import { isBrowser } from '../../utils/helpers'

const EMAIL_KEY = '__easyCodersEmail'
const PAGES = ['/contact/', '/about', '/search/', '/success/', '/404/', '/category/']

class Wrapper extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      display: true
    }

    this.handleSuccess = this.handleSuccess.bind(this)
  }

  componentDidMount() {
    this.checkStorage()
  }

  checkStorage() {
    if (isBrowser()) {
      if (window.localStorage.getItem(EMAIL_KEY)) {
        this.setState({ display: false })
      }

      if (PAGES.includes(this.props.location)) {
        this.setState({ display: false })
      }
    }
  }

  handleSuccess() {
    if (isBrowser()) {
      window.localStorage.setItem(EMAIL_KEY, 'true')
      this.setState({ display: false })
    }
    console.log('Thanks for signing up!')
  }

  render() {
    if (!this.state.display) return null
    const { theme } = this.props
    return (
      <div className="email-wrapper">
        <EmailForm successHandler={this.handleSuccess} />

        {/* --- STYLES --- */}
        <style jsx>{`
          .email-wrapper {
            width: 90%;
            margin: auto;
            margin-bottom: 25px;
            padding: 10px;
            border-radius: ${theme.size.radius.default};
            border: 1px solid ${theme.line.color};
          }

          @from-width desktop {
            max-width: 700px;
            padding: 20px;
            border-radius: ${theme.size.radius.default};
            border: 1px solid ${theme.line.color};
          }
        `}</style>
      </div>
    )
  }
}

Wrapper.propTypes = {
  theme: PropTypes.object,
  location: PropTypes.string
}

export default Wrapper
